.wrapper {
  padding-top: var(--spacing-3);

  && > * {
    margin-bottom: var(--spacing-14);

    &:last-child,
    &.breadcrumb {
      margin-bottom: 0;
    }
  }

  @media (--tablet) {
    padding-top: var(--spacing-10);
  }
}

.breadcrumb {
  padding-top: var(--spacing-3);
}

.twoColLayout {
  display: flex;
  gap: 48px;

  & .contentCol {
    max-width: 100%;
  }

  & .adCol {
    display: none;
    width: 300px;

    @media (--tablet-l) {
      display: block;
    }
  }
}
