.wrpr {
  width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    border-left: 0;
  }

  th,
  td {
    padding: var(--spacing-3);
    text-align: left;
    border: 1px solid var(--color-neutral-200);
    font-size: 14px;
    line-height: 1.59;

    &.rowTitle {
      position: relative;
      width: 0;
      padding: 0;
      border: 0;
      font-weight: 700;
      font-size: 10px;
      line-height: 1.4;

      & > span {
        position: absolute;
        top: var(--spacing-3);
        left: var(--spacing-3);
        white-space: nowrap;
      }
    }

    &.highlighted {
      background-color: var(--color-secondary-500);
    }
  }

  th {
    background-color: var(--color-primary-100);
    border-color: var(--color-neutral-100);
    border-bottom-color: var(--color-neutral-200);
    font-weight: 700;
  }

  td {
    padding-top: 30px;
  }

  @media (--tablet) {
    th,
    td {
      width: calc(100% - 120px / 3);
      &.rowTitle {
        padding: var(--spacing-3);
        width: 120px;
        background-color: var(--color-primary-50);
        border: solid 1px var(--color-neutral-100);
        font-weight: 500;
        font-size: 14px;

        & > span {
          position: static;
        }
      }
    }

    th {
      &.rowTitle {
        border-top-color: var(--color-primary-50);
        border-left-color: var(--color-primary-50);
      }
    }

    td {
      padding-top: var(--spacing-3);
    }
  }

  @media (--desktop-l) {
    th,
    td {
      padding: var(--spacing-4) var(--spacing-6);
      font-size: 16px;
      line-height: normal;

      &.rowTitle {
        padding: var(--spacing-4) var(--spacing-6);
        width: 200px;
        font-size: 16px;
      }
    }

    td {
      padding-top: var(--spacing-4);
      height: 100px;
    }
  }
}

.lineClamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
